import { I18n } from "i18n-js";
import moment from "moment";
import translations from "../../locales.json";

export const i18n = new I18n(translations);
i18n.locale = document.documentElement.lang || "en";
i18n.enableFallback = true;

window.i18n = i18n;
moment.locale(i18n.locale);

export const i18nARAttr = (key) => i18n.t(`activerecord.attributes.${key}`);
window.i18nARAttr = i18nARAttr;

export const i18nARError = (fieldKey, errorKey) => {
  const error = i18n.t(`errors.messages.${errorKey}`);
  return `${i18nARAttr(fieldKey)} ${error}`;
};
window.i18nARError = i18nARError;
